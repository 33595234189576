import React, {useEffect, useState} from 'react';
import './Base.css';
import Header from "../Header/Header";
import Body from "../Body/Body";
import axios from "axios";

const api_key = process.env.REACT_OPENWEATHER_API_KEY;
const baseURL = process.env.REACT_OPENWEATHER_BASE;

function Base() {

    const [weatherData, setWeatherData] = useState(null);

    const getData = async () => {
        await axios.get("https://api.openweathermap.org/data/2.5/weather?lat=44.34&lon=10.99&appid=aae395b952b18560a89c9d6c91ff1ce4")
            .then((response) => {
                setWeatherData(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
            getData();
            console.log(weatherData);
        },
        [])
    return (
        <div className="Base">
            <Header/>
            {/*<h1>{weatherData.data}</h1>*/}
            <Body/>
            Base Component
        </div>
    )
}

export default Base;
