import React from 'react';
import './Header.css';

const Header = () => (
    <div className="Header">
        Header Component
    </div>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
