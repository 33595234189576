import './App.css';
import Base from "./components/Base/Base";

function App() {
  return (
    <div className="App">
        <Base/>
    </div>
  );
}

export default App;
