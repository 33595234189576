import React from 'react';
import './Body.css';

const Body = () => (
    <div className="Body">
        <section className="vh-100 section-style">
            <div className="container py-5 h-100">

                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-md-8 col-lg-6 col-xl-4">

                        <div className="card custom-card">
                            <div className="card-body p-4">

                                <div className="d-flex">
                                    <h6 className="flex-grow-1">Warsaw</h6>
                                    <h6>15:07</h6>
                                </div>

                                <div className="d-flex flex-column text-center mt-5 mb-4">
                                    <h6 className="display-4 mb-0 font-weight-bold custom-h6"> 13°C </h6>
                                    <span className="small custom-span">Stormy</span>
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 custom-card-body">
                                        <div><i className="fas fa-wind fa-fw custom-icon"></i> <span
                                            className="ms-1"> 40 km/h
                  </span></div>
                                        <div><i className="fas fa-tint fa-fw custom-icon"></i> <span
                                            className="ms-1"> 84% </span>
                                        </div>
                                        <div><i className="fas fa-sun fa-fw custom-icon"></i> <span
                                            className="ms-1"> 0.2h </span>
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-weather/ilu1.webp"
                                            width="100px"/>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    </div>
);

Body.propTypes = {};

Body.defaultProps = {};

export default Body;
